/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
export type LinkDetailsFragment = {
  __typename?: 'Link'
  name: string
  url: string
}

export const LinkDetailsFragmentDoc = gql`
  fragment LinkDetails on Link {
    name
    url
  }
`
