/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { LinkDetailsFragmentDoc } from './linkDetails.fragment.gen'
export type PromoterDetailsFragment = {
  __typename: 'Promoter'
  id: string
  name: string
  description: string
  picture?: string | null
  slug: string
  links: Array<{ __typename?: 'Link'; name: string; url: string }>
}

export type PromoterSearchDetailsFragment = {
  __typename: 'Promoter'
  id: string
  name: string
  picture?: string | null
  slug: string
}

export const PromoterDetailsFragmentDoc = gql`
  fragment PromoterDetails on Promoter {
    __typename
    id
    name
    description
    picture
    slug
    links {
      ...LinkDetails
    }
  }
  ${LinkDetailsFragmentDoc}
`
export const PromoterSearchDetailsFragmentDoc = gql`
  fragment PromoterSearchDetails on Promoter {
    __typename
    id
    name
    picture
    slug
  }
`
